import { useContext } from 'react';
import { CartContext } from '@providers/CartProvider';

import CartView from './Cart';

type Props = {
  isMobile?: boolean;
};

const Cart = ({ isMobile }: Props): JSX.Element => {
  const { cart, isLoading, onDeleteFromCart } = useContext(CartContext);

  return (
    <CartView
      products={cart.items}
      isMobile={isMobile}
      isLoading={isLoading}
      amounts={{ quantity: cart.quantity, netAmount: cart.itemsTotalPrice, grossAmount: cart.itemsTotalGrossPrice }}
      onDeleteFromCart={onDeleteFromCart}
    />
  );
};

export default Cart;
